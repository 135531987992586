import {Injectable} from '@angular/core';
import {concatMap, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {
	AuthService,
	CustomSettingsService,
	Employee,
	EmployeeService,
	LanguageService,
	Role,
	UserService
} from '@customcoding/shared';
import {HttpClient} from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class DoctorService {
	doctorRoleName: string;
	doctorRoleId: number;

	constructor(
		private http: HttpClient,
		private language: LanguageService,
		private settingsService: CustomSettingsService,
		private employeeService: EmployeeService,
		private auth: AuthService,
		private userService: UserService
	) {
	}

	getAvailableDoctors(): Observable<Employee[]> {
		const currentUser = this.auth.getCurrentUser();

		return this.getDoctors().pipe(
			tap((doctors: Employee[]) => {

				let availableDoctors = doctors;
				//TODO, does this makes sense?
				if (currentUser.roles.map(role => role.id == this.doctorRoleId)) {
					availableDoctors = availableDoctors.filter((doctor: Employee) => doctor.user_id !== currentUser.id);
				}

				return availableDoctors;
			})
		);
	}

	getDoctors(): Observable<Employee[]> {
		return this.settingsService.getCachedByKey('DOCTOR_ROLE_ID').pipe(
			concatMap(setting => {
				if (!setting) return of(null);
				return this.http.get(`/api/role/${setting.value}`);
			}),
			concatMap((role: Role) => {
				this.doctorRoleId = role.id;
				this.doctorRoleName = role.name; //TODO @Carsten, add roleId as filter, roleName is not a good idea!

				return this.employeeService.all({
					filters: {roleName:role.name},
					include: ['user.person']
				});
			}));
	}

	getDoctorsUsers() {
		return this.settingsService.getCachedByKey('DOCTOR_ROLE_ID').pipe(
			concatMap(setting => {
				if (!setting) return of(null);
				return this.http.get(`/api/role/${setting.value}`);
			}),
			concatMap((role: Role) => {
				this.doctorRoleName = role.name;

				return this.userService.all({
					filters: {roleName: this.doctorRoleName},
					include: ['person']
				});
			}));
	}

	createDoctorFolder(patientId: number, doctorId: number) {
		return this.http.post(`/api/scap/patient/${patientId}/cloud/doctor-folders/${doctorId}`, {});
	}


	async currentUserIsDoctor(): Promise<boolean> {
		try {
			const setting = await this.settingsService.getCachedByKey('DOCTOR_ROLE_ID').toPromise();
			const currentUser = await this.auth.getCurrentUserAsync().catch(() => {
				return null;
			});

			if (!currentUser) {
				return false;
			}

			return currentUser?.roles.some(role => role.id === setting.value) ?? false;
		} catch (err) {
			return false;
		}
	}

	saveDoctorSettings(employeeId: number, data: any) {
		return this.http.patch(`/api/scap/employee/${employeeId}/doctor-settings`, data);
	}
}
