<div class="modal-body" style="padding:1rem;">
    <div style="display:flex; align-items: center">
        <div style="margin-left:10px;  transform: scale(0.5);">
            <div class="modal-loader"></div>
        </div>
        <div style="margin-left:30px;font-weight:bold">
            {{text | translate}}
        </div>
    </div>
</div>
