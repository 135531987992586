import {Injectable} from '@angular/core';
import {SchoolClass} from "../models/school.obj";
import {Observable} from "rxjs";
import _ from "lodash";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {CacheService} from "@customcoding/shared";

@Injectable({
	providedIn: 'root'
})
export class SchoolService {

	constructor(private http: HttpClient, private cache: CacheService) {
	}

	private static groupClasses(classes: SchoolClass[]): { [type: number]: SchoolClass[] } {
		return _.groupBy(classes, 'type');
	}

	public getNames(): Observable<IdAndName[]> {
		return this.http.get<IdAndName[]>("/api/scap/school-name");
	}

	public getDirections(): Observable<IdAndName[]> {
		return this.cache.get("/api/scap/school-direction");
	}

	public getClasses(): Observable<SchoolClass[]> {
		return this.cache.get("/api/scap/school-class");
	}

	public groupedClasses(): Observable<{ [type: number]: SchoolClass[] }> {
		return this.getClasses().pipe(map(c => SchoolService.groupClasses(c)));
	}

	existsOnBackend(value: {id: number | string}): boolean {
		return value.id && !String(value.id).startsWith('unsaved_');
	}
}

type IdAndName = { id: number, name: string };
