import {Injectable} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {LoadingModalComponent} from "../components/loading-modal/loading-modal.component";

//TODO @Victor: move to core/shared package after testing

@Injectable({
    providedIn: 'root'
})
export class LoadingModalService {

    //@ViewChild('loadingModal') loadingModal: TemplateRef<any>;
	constructor(
        public modalService: BsModalService
    ) { }


    show(text:string = null){
        //alternative with BsModalService:
        //const modalRef = this.modalService.open(LoadingModalComponent, { centered: true });
        //modalRef.componentInstance.text

        const modalRef = this.modalService.show(LoadingModalComponent,{ignoreBackdropClick:true, keyboard:false, class:"modal-sm modal-dialog-centered cuco-loading-modal"});
        if(text){
            modalRef.content.text = text;
        }

        return modalRef;
    }

    hide(modalRef: any){
        if (modalRef) {
            modalRef.close();
        }
    }

}
