import {Injectable, NgZone} from '@angular/core';
import {listen} from '@tauri-apps/api/event';
import {Router} from "@angular/router";

@Injectable({
	providedIn: 'root'
})
export class TauriService {
	isTauriApp: boolean;

	constructor(private router: Router,private zone: NgZone) {
	}

	isDesktopApp(){

		if(typeof this.isTauriApp === 'undefined')
			this.isTauriApp = '__TAURI__' in window;
		return this.isTauriApp;
	}

	async initDesktopFeatures(){

		console.log("tauri listeners");
		const unlisten = await listen('tauri://menu', (event) => {
			console.log(`menu event fired: ${event.windowLabel}, payload: ${event.payload}`);
			if(event.payload == 'settings'){
				this.zone.run(() => {
					this.router.navigate(["/user/profile/personal-information"]);
				});
			}
		});


		//unlisten();

		/* for angular >= 17
		useEffect(() => {
			listen("menu-event", (e) => {
				console.log(e);
			});
		}, []);
		*/
	}
}
