import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SplashScreenService {

    private _data: BehaviorSubject<SplashScreenData> = new BehaviorSubject<SplashScreenData>({show:true,message:null,progress:0});

    private _show: boolean;
    private _message: string;
    private _progress: number;

    constructor() {

        /*
        this.show = true;
        this.message = "verbinde";
        this.progress = 0;
        this.updateData();
        */
        //this.show = true;

        //this.faker();

    }

    //only for testing...
   public faker() {

        this.progress = 25;
        this.message = "lade Basis Module";
        setTimeout(() => {
            this.progress = 50;
            this.message = "lade Scap Module";
        }, 1000);

        setTimeout(() => {
            this.progress = 75;
            this.message = "lade Mitarbeiter";
        }, 3000);

        setTimeout(() => {
            this.progress = 75;
            this.message = "lade Kalender";
        }, 4000);

        setTimeout(() => {
            this.progress = 100;
            this.message = "";
            this.show = false;

        }, 5000);

    }

    get data(){
        return this._data;
    }
    get show() {
        //console.log("splash get = ", this._show);
        return this._show;
    }

    set show(val: boolean) {
        this._show = val;
        //console.log("splash set  = ", this._show);
        this.updateData();
    }

    get message() {
        return this._message;
    }

    set message(message: string) {
        this._message = message;
        this.updateData();
    }

    get progress() {
        return this._progress;
    }

    set progress(progress: number) {
        this._progress = progress;
        this.updateData();
    }

    updateData(){
        this._data.next({show:this._show,message:this._message,progress:this._progress});
    }
}

export class SplashScreenData {
    show: boolean;
    message: string;
    progress: number;
}
