import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent, CoreComponent, CustomPreloadingStrategy, NotFoundComponent} from '@customcoding/core';
import {AuthGuard} from '@customcoding/shared';

const routes: Routes = [
	{
		path: '',
		component: CoreComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'activity-log',
				loadChildren: () => import('@customcoding/core').then(m => m.ActivityLogModule),
			},
			{
				path: 'employee',
				loadChildren: () => import('@customcoding/core').then(m => m.EmployeeModule),
			},
			{
				path: 'user',
				loadChildren: () => import('@customcoding/core').then(m => m.UserModule),
			},
			{
				path: 'company',
				loadChildren: () => import('@customcoding/core').then(m => m.CompanyModule),
			},
			{
				path: 'department',
				loadChildren: () => import('@customcoding/core').then(m => m.DepartmentModule),
			},
			{
				path: 'dashboard',
				loadChildren: () => import('@customcoding/core').then(m => m.DashboardModule),
			},
			{
				path: 'settings',
				loadChildren: () => import('@customcoding/core').then(m => m.SettingsModule),
			},
			{
				path: 'officedocs',
				loadChildren: () => import('@customcoding/core').then(m => m.OfficeDocsModule),
			},
			{
				path: 'scap',
				loadChildren: () => import('./scap/scap.module').then(m => m.ScapModule),
				data: {
					applyPreload: true
				}
			},
			{
				path: 'dashboard',
				redirectTo: 'dashboard'
			},
			{
            	path: '',
            	pathMatch: 'full',
            	redirectTo: 'dashboard',
            }
		]
	},
	{
		path: 'auth',
		component: AuthComponent,
		loadChildren: () => import('@customcoding/core').then(m => m.AuthModule)
	},
	{
    	path: 'external',
    	loadChildren: () => import('./external/external.module').then(m => m.ExternalModule)
    },
	{
        path: 'error',
        loadChildren: () => import('@customcoding/core').then(m => m.ErrorPagesModule)
    },
    {path: '**', redirectTo: '/error/404'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: false, preloadingStrategy: CustomPreloadingStrategy})],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}


