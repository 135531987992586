<div id="splashscreen" *ngIf="data?.show">
    <div id="splashscreen-center">
        <img id="splashscreen-logo" src="/assets/media/logos/logo.png">

        <div style="position:relative">
            <div class="progress-wrapper">

                <div class="progress" style="height: 6px;" [hidden]="!data.message"><!--[@widthChange]="splashScreenService.progress"-->
                    <div class="progress-bar bg-brand" role="progressbar"   [style.width.%]="data.progress"  [attr.aria-valuenow]="data.progress"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="entity-info">
                    <ng-container *ngIf="data.message">
                    {{data.message}}<span [hidden]="data.message.length < 2">...</span>
                </ng-container>
                </div>
                <!--<div class="entity-info">Patienten werden geladen...</div>-->
            </div>

        </div>
    </div>
</div>
