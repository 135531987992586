import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {


  @ViewChild('customContentRender', {read: ViewContainerRef}) customContentRender: ViewContainerRef;


  @Input() text:string = 'loading...';

  constructor() { }


  ngOnInit(): void {

  }

}
