import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {AuthService, EchoService, SettingsService} from "@customcoding/shared";
import {TauriService} from "./scap/shared/services/tauri.service";
import {getVersion} from "@tauri-apps/api/app";
import {TauriFilesystemService} from "./scap/shared/services/tauri-filesystem.service";
import {DoctorService} from "./scap/shared/services/doctor.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [NgbModalConfig, NgbModal]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

	lang = new Subject<string>();
	title = 'dashboard-app';
	demo = false;

	constructor(private pushService: EchoService,
							private tauri: TauriService,
							private tauriFileService: TauriFilesystemService,
							private doctorService: DoctorService,
							private settings: SettingsService,
							private ngbModalConfig: NgbModalConfig,
							private authService: AuthService) {
		this.setUpNGBConfigs();
	}


	ngOnInit(): void {

		if(this.tauri.isDesktopApp()){
			this.setAppVersionMeta();
			this.tauri.initDesktopFeatures();
			(async () => {
				console.log('current user', this.authService.getCurrentUser());
				const user = await this.authService.getCurrentUserAsync();
				if (await this.doctorService.currentUserIsDoctor()) {
					await this.tauriFileService.offlineSyncLabFolder();
				}
			})();
		}

		this.demo = this.settings.get('BETA');
		//this.demo = environment?.beta; alternative
	}
	ngAfterViewInit() {
		console.log('current user', this.authService.getCurrentUser());
	}

	async setAppVersionMeta(){
		const appVersion = await getVersion();
		this.settings.set('VERSION',appVersion + '-desktop')
	}

	ngOnDestroy() {
		this.pushService.leavePushChannel();
	}

	private setUpNGBConfigs() {
		this.ngbModalConfig.scrollable = true;
	}

}
