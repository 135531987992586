export const environment = {
	production: true,
	apiUrl: '/api',
    pusherKey: 'scap897079688e2xx04dff4b23613228',
	sentryDSN: null,
		socketUrl: "development.scap.dev.cuco.lu",

		CLOUD_URL: "cloud.{BASE_URL}",

};
