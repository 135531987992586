import {MbscModule} from '@mobiscroll/angular';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {
	ApiUrlInterceptor,
	Config,
	CountryService,
	createErrorHandler,
	EncodeUrlInterceptor,
	ErrorInterceptor,
	getDatepickerConfig,
	JWTInterceptor,
	LanguageService,
	SharedModule,
	TimeConfig
} from '@customcoding/shared';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDatepickerConfig, BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DragulaModule} from 'ng2-dragula';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {environment} from '../environments/environment';
import menuConfig from './menu.json';
import settings from './settings.json';
import {CoreModule, CustomPreloadingStrategy, TEMPLATE_CONFIG} from '@customcoding/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {LightboxModule} from 'ngx-lightbox';
import {AppConfigService} from './scap/shared/services/app-config.service';
import {AngularSplitModule} from "angular-split";
import {TauriService} from "./scap/shared/services/tauri.service";
import {LoadingModalService} from "./scap/shared/services/loadingModal.service";
import {PatientProfileDataService} from "./scap/patient/patient-profile/patient-profile-data.service";
import {DragDropModule} from "primeng/dragdrop";
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from "@angular/common";
import {MessageService} from "primeng/api";
import {NgScrollbarModule} from "ngx-scrollbar";
import {SplashScreenComponent} from "./scap/shared/components/splash-screen/splash-screen.component";
import {captureException} from '@sentry/angular-ivy';

registerLocaleData(localeDe);

export class SentryErrorHandler implements ErrorHandler {
	handleError(err: any): void {
		// createErrorHandler({showDialog: false});
		captureException(err);
	}
}


export function initializeApp(tauriService: TauriService) {
	return () => tauriService.isDesktopApp();
}

@NgModule({
    declarations: [
        AppComponent,
        SplashScreenComponent
    ],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MbscModule,
		HttpClientModule,
		FormsModule,
		AppRoutingModule,
		TableModule,
		ModalModule.forRoot(),
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		AccordionModule.forRoot(),
		DragulaModule.forRoot(),
		DragDropModule,
		LightboxModule,
		AngularSplitModule,
		NgScrollbarModule,
		// ngx-translate and the loader module
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpBackend]
			},
			isolate: false
		}),

		CoreModule,
		SharedModule.forRoot(environment.apiUrl, menuConfig, settings)
	],
	exports: [
		TranslateModule
	],
	providers: [
		TauriService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			multi: true,
			deps: [TauriService],
		},
		AppConfigService,
		{ provide: Config, useExisting: AppConfigService },
		{provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: EncodeUrlInterceptor, multi: true},
		{provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
		{provide: BsDatepickerConfig, useFactory: getDatepickerConfig, deps: [TranslateService]},
		{provide: TimeConfig, useValue: {startHours: 7, endHours: 22, daysOfWeek: [1, 2, 3, 4, 5]}},
		{provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false })},
		{
			provide: TEMPLATE_CONFIG, useValue: {
				variables: ['bornDate', 'oTeamDate', 'coordinatorFirstname', 'coordinatorLastname', 'coordinatorEmail',
					'coordinatorPhone', 'created_at', 'patientFirstname', 'patientLastname', 'caretakerFullname',
					'caretakerSalutation', 'coordinatorFullname', 'patientFullname', 'patientLivingAddressHeader',
					'companyMainAddressHeader', 'livingAddressHouseNumber',	'livingAddressStreet', 'livingAddressCity',
					'livingAddressPostalCode', 'livingAddressCountry', 'mainAddressStreet', 'mainAddressCity', 'mainAddressHouseNumber',
					'mainAddressCountry', 'mainAddressPostalCode']
			}
		},
		CustomPreloadingStrategy,
		LoadingModalService,
		PatientProfileDataService,
		MessageService
	],
	bootstrap: [AppComponent]
})
export class AppModule {

	constructor(translate: TranslateService, languageService: LanguageService, countryService: CountryService) {

		translate.setDefaultLang('de');

		languageService.register();
		countryService.setup();
	}

}

// Using HTTPBackend to ignore interceptors as language files are not part of the api url
// required for AOT compilation
export function HttpLoaderFactory(httpBackend: HttpBackend) {
	const http = new HttpClient(httpBackend);
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
