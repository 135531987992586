<div class="demo-banner-grid">
	<div *ngIf="demo" class="demo-banner">
		<img src="assets/media/application/demo-icon.svg"/><span class="ml-2">Sie nutzen momentan eine Demo-Version von Miro.</span>
	</div>
	<div style="grid-area: noDemo" [class.demo-environment]="demo">
		<router-outlet></router-outlet>

		<app-snackbar-container style="z-index: 9999999;pointer-events: none;"></app-snackbar-container>

		<app-toast-message-container style="z-index: 9999999;"></app-toast-message-container>
	</div>
</div>
<app-splash-screen style=" z-index: 9999999; pointer-events: none;"></app-splash-screen>
