import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Patient} from '../../shared/models/patient.obj';
import {Intervention} from '../../shared/models/intervention.obj';
import {tap} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PatientProfileDataService {
	public interventionsLoaded: EventEmitter<Intervention[]> = new EventEmitter<Intervention[]>();
	public patient: Patient;
	public interventions: Intervention[];

	constructor(private http: HttpClient) {
	}

	getCurrentPatient(): Patient{

		return this.patient;

	}
	loadPatient(id: number) {

		return this.http.get<Patient>("/api/scap/patient/" + id).pipe(
			tap(data => {

				this.patient = data;
				console.log("load patient() = ",this.patient);
			})
		);
	}

	loadInterventions() {
		this.http.get<Intervention[]>(`/api/scap/patient/${this.patient.id}/interventions`).subscribe(
			interventions => {
				this.interventions = interventions;
				this.interventionsLoaded.emit(interventions);
			}, _error => this.interventions = []
		);
	}
}
