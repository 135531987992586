import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {SplashScreenData, SplashScreenService} from "../../services/splash-screen.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    animations: [
        trigger('widthChange', [
            transition(':increment', [
                //style({ width: '0%' }),
                animate('0.5s', style({width: '*'})),
            ]),
        ]),
    ],
})
export class SplashScreenComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    data:SplashScreenData;

    constructor(public splashScreenService: SplashScreenService,  private ngZone: NgZone,private cdr: ChangeDetectorRef) {


    }

    ngOnInit(): void {
        this.splashScreenService.data.subscribe((data) => {
            // this.ngZone.run(() => {
            console.log("splashScreenData update = ", data);
            this.data = data;
            this.cdr.detectChanges();
            // });
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
